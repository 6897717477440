<template>
  <div class="container-fluid mt-5">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-6">
              <h3 class="mb-0">
                {{ $t("PURCHASES_ORDERS.PURCHASES_ORDERS_LIST") }}
              </h3>
            </div>
            <div class="col-6 text-right">
              <base-button
                type="primary"
                icon
                size="sm"
                @click="addPurchasesOrder()"
                v-if="
                  $currentUserCan($permissions.PERM_CREATE_PURCHASES_ORDERS)
                "
              >
                <span class="btn-inner--icon">
                  <i class="fas fa-plus"></i>
                </span>
                <span class="btn-inner--text">
                  {{ $t("PURCHASES_ORDERS.ADD_PURCHASES_ORDER") }}
                </span>
              </base-button>

              <notification-subscription
                v-if="$currentUserCan($permissions.PERM_VIEW_PURCHASES_ORDERS)"
                :objectType="'purchases-orders'"
                :events="{
                  CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
            </div>
          </div>
        </template>

        <purchases-order-list-table />
      </card>
    </div>
  </div>
</template>
<script>
import { Button } from "element-ui";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import PurchasesOrderListTable from "./partials/PurchasesOrderListTable.vue";

export default {
  layout: "DashboardLayout",

  components: {
    PurchasesOrderListTable,
    NotificationSubscription,
    [Button.name]: Button,
  },

  mixins: [],

  computed: {},

  watch: {},

  methods: {
    addPurchasesOrder() {
      this.$router.push({ name: "Add PurchasesOrder" });
    },
  },
};
</script>
